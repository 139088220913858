<template>
  <CohortAddWizard
    :value="currentCohort"
    @save="saveCohort"
  />
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import CohortAddWizard from '../components/CohortAddWizard.vue'

const { mapActions } = createNamespacedHelpers('cohort')

export default {
  components: {
    CohortAddWizard,
  },
  data() {
    return {
      currentCohort: {},
    }
  },
  methods: {
    saveCohort({ data, close }) {
      this.createCohort(data).then(() => {
        if (close) {
          this.$router.push({ name: 'purchase-journey' })
        }
      }, () => {
      })
    },
    ...mapActions(['createCohort']),
  },
}
</script>
